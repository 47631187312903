import { createStore } from "vuex";
import { categoryList, kindList, channeList, subchannelLists } from "@/api/terminalList.js";
import authcModule from './modules/authc'//引入模块
export default createStore({
  modules: {
    authc: authcModule, //添加模块  currency为模块命名空间自动注册路径
  },
  state: {
    newMessages: [],//用户的未读信息
    userInfo: {}, //用户信息
    sideBarApp: [], //侧边栏涉及的其他app名字和路径
    isLogin: false, //是否登录过如果没有就先跑登录等几个接口
    breadcrumb: ["主数据中心", "终端管理", "终端列表"],
    lng: 116.404,
    lat: 39.915,
    loadingStatus: false, //全局loading状态
    //品类管理相关
    categoryEdit: false,//全局只能有一个编辑或者新增当此状态存在时禁用新增分类 更改排序 编辑 删除 此状态和渠道管理共用
    gradeCode1: [],
    grade1: "",
    gradeCode2: [],
    grade2: "",
    gradeCode3: [],
    grade3: "",
    gradeCode4: [],
    grade4: "",
    grade1SearchInfo: "", //二级品类的搜索栏信息
    grade2SearchInfo: "", //3级品类的搜索栏信息
    grade3SearchInfo: "", //4级品类的搜索栏信息
    grade4SearchInfo: "", //5级品类的搜索栏信息
    //渠道管理相关
    channelCode1: [],
    channel1: "",
    channelCode2: [],
    channel2: "",
    channelCode3: [],
    channel3: "",
    channel1SearchInfo: "", //渠道管理中的第二项
    channel2SearchInfo: "", //渠道管理中的第3项
    channel3SearchInfo: "", //渠道管理中的第4项
    reqList: [1, 1],//所有请求的数组 用于终止请求使用
  },
  mutations: {
    reqList(state, reqList) {
      state.reqList = reqList;
    },
    changeCategoryEdit(state, categoryEdit) {
      state.categoryEdit = categoryEdit;
    },
    changeNewMessages(state, newMessages) {
      state.newMessages = newMessages;
    },
    changeUserInfo(state, userInfo) {
      state.userInfo = userInfo;
    },
    changeSideBarApp(state, sideBarApp) {
      state.sideBarApp = sideBarApp;
    },
    changeisLogin(state, isLogin) {
      state.isLogin = isLogin;
    },
    changeloadingStatus(state, loadingStatus) {
      state.loadingStatus = loadingStatus;
    },
    changeBreadcrumb(state, breadcrumb) {
      state.breadcrumb = breadcrumb;
    },
    changelng(state, lng) {
      state.lng = lng;
    },
    changeGradeCode1(state: any, gradeCode1) {
      state.grade1 = gradeCode1;
      categoryList(gradeCode1).then((res: any) => {
        let items = res.data.data;
        items.forEach((element, i) => {
          element.beforeSort = element.sort;
          element.people = 2;
          element.subordinate = element.isHave;
        });
        state.gradeCode1 = items;
      });
    },
    changeGradeCode2(state: any, gradeCode2) {
      state.grade2 = gradeCode2;
      categoryList(gradeCode2).then((res: any) => {
        let items = res.data.data;
        items.forEach((element, i) => {
          element.beforeSort = element.sort;
          element.people = 3;
          element.subordinate = element.isHave;
        });
        state.gradeCode2 = items;
      });
    },
    changeGradeCode3(state: any, gradeCode3) {
      state.grade3 = gradeCode3;
      categoryList(gradeCode3).then((res: any) => {
        let items = res.data.data;
        items.forEach((element, i) => {
          element.beforeSort = element.sort;
          element.people = 4;
          element.subordinate = element.isHave;
        });
        state.gradeCode3 = items;
      });
    },
    changeGradeCode4(state: any, gradeCode4) {
      state.grade4 = gradeCode4;
      categoryList(gradeCode4).then((res: any) => {
        let items = res.data.data;
        items.forEach((element, i) => {
          element.beforeSort = element.sort;
          element.people = 5;
          element.subordinate = element.isHave;
        });
        state.gradeCode4 = items;
      });
    },
    changeGrade1SearchInfo(state, grade1SearchInfo) {
      state.grade1SearchInfo = grade1SearchInfo;
    },
    changeGrade2SearchInfo(state, grade2SearchInfo) {
      state.grade2SearchInfo = grade2SearchInfo;
    },
    changeGrade3SearchInfo(state, grade3SearchInfo) {
      state.grade3SearchInfo = grade3SearchInfo;
    },
    changeGrade4SearchInfo(state, grade4SearchInfo) {
      state.grade4SearchInfo = grade4SearchInfo;
    },
    //渠道管理相关   四级暂时未加  以后会关联子渠道再添加
    changeChannelCode1(state: any, channelCode1) {
      state.channel1 = channelCode1;
      kindList(channelCode1).then((res: any) => {
        let items = res.data.data;
        items.forEach((element, i) => {
          element.beforeSort = element.sort;
          element.people = 2;
          element.subordinate = element.isHave;
          element.categoryName = element.name;
          element.categoryCode = element.code;
        });
        state.channelCode1 = items;
      });
    },
    changeChannelCode2(state: any, channelCode2) {
      state.channel2 = channelCode2;
      channeList(state.channel1, channelCode2).then((res: any) => {
        let items = res.data.data;
        items.forEach((element, i) => {
          element.beforeSort = element.sort;
          element.people = 3;
          element.subordinate = element.isHave;
          element.categoryName = element.name;
          element.categoryCode = element.code;
        });
        state.channelCode2 = items;
      });
    },
    changeChannelCode3(state: any, channelCode3) {
      state.channel3 = channelCode3;
      subchannelLists(state.channel1, state.channel2, channelCode3).then((res: any) => {
        let items = res.data.data;
        items.forEach((element, i) => {
          element.beforeSort = element.sort;
          element.people = 4;
          element.subordinate = element.isHave;
          element.categoryName = element.name;
          element.categoryCode = element.code;
        });
        state.channelCode3 = items;
      });
    },
    changeChannel1SearchInfo(state, channel1SearchInfo) {
      state.channel1SearchInfo = channel1SearchInfo;
    },
    changeChannel2SearchInfo(state, channel2SearchInfo) {
      state.channel2SearchInfo = channel2SearchInfo;
    },
    changeChannel3SearchInfo(state, channel3SearchInfo) {
      state.channel3SearchInfo = channel3SearchInfo;
    },
  },
  actions: {},
});
