
import { defineComponent } from "vue";
import { Layout, Breadcrumb, Spin } from "ant-design-vue";
import { HomeFilled, RightOutlined } from "@ant-design/icons-vue";

const { Content } = Layout;
const { Item } = Breadcrumb;

export default defineComponent({
  components: {
    Content,
    Breadcrumb,
    Layout,
    Item,
    HomeFilled,
    RightOutlined,
    Spin,
  },
  props: {
    breadcrumb: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      minHeight: "90px",
    };
  },
  mounted() {
    this.minHeight = document.body.clientHeight - 120 + "px";
    // console.log(this.breadcrumb[0]);
  },
});
