
import { defineComponent } from "vue";
import zhCN from "ant-design-vue/lib/locale-provider/zh_CN";
import { ConfigProvider } from "ant-design-vue";
export default defineComponent({
  data() {
    return {
      locale: zhCN,
    };
  },
  components: {
    ConfigProvider,
  },
});
