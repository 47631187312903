import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2360618f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 1 }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RightOutlined = _resolveComponent("RightOutlined")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Item = _resolveComponent("Item")!
  const _component_Breadcrumb = _resolveComponent("Breadcrumb")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Spin = _resolveComponent("Spin")!
  const _component_Content = _resolveComponent("Content", true)!
  const _component_Layout = _resolveComponent("Layout")!

  return (_openBlock(), _createBlock(_component_Layout, null, {
    default: _withCtx(() => [
      _createVNode(_component_Breadcrumb, { class: "breadcrumb" }, {
        separator: _withCtx(() => [
          _createVNode(_component_RightOutlined)
        ]),
        default: _withCtx(() => [
          (_ctx.breadcrumb[0][[_ctx.breadcrumb[0].length - 1]].meta.breadcrumb)
            ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.breadcrumb[0][[_ctx.breadcrumb[0].length - 1]].meta
          .breadcrumb, (data) => {
                return (_openBlock(), _createBlock(_component_Item, {
                  key: data.title,
                  class: "breadcrumbItme"
                }, {
                  default: _withCtx(() => [
                    (data.path)
                      ? (_openBlock(), _createBlock(_component_router_link, {
                          key: 0,
                          to: data.path
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(data.title), 1)
                          ]),
                          _: 2
                        }, 1032, ["to"]))
                      : (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(data.title), 1))
                  ]),
                  _: 2
                }, 1024))
              }), 128))
            : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.breadcrumb[0], (data) => {
                return (_openBlock(), _createBlock(_component_Item, {
                  key: data.title,
                  class: "breadcrumbItme"
                }, {
                  default: _withCtx(() => [
                    (!data.meta.hidden)
                      ? (_openBlock(), _createBlock(_component_router_link, {
                          key: 0,
                          to: data.path
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(data.name), 1)
                          ]),
                          _: 2
                        }, 1032, ["to"]))
                      : (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(data.name), 1))
                  ]),
                  _: 2
                }, 1024))
              }), 128))
        ]),
        _: 1
      }),
      _createVNode(_component_Content, {
        style: _normalizeStyle({
        padding: '20px',
        margin: 0,
        minHeight: _ctx.minHeight,
        backgroundColor: '#F5F5F5',
      }),
        class: "example"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_Spin, {
            class: "spin",
            spinning: _ctx.$store.state.loadingStatus,
            delay: 500,
            tip: "努力加载中..."
          }, {
            default: _withCtx(() => [
              _createVNode(_component_router_view)
            ]),
            _: 1
          }, 8, ["spinning"])
        ]),
        _: 1
      }, 8, ["style"])
    ]),
    _: 1
  }))
}