// 按钮权限
export const btnAuthc = {
    //终端管理相关按钮
    "ButtonBasic_pg_basiData_terminalList_state": {
        "method": "GET",
        "path": "/terminalBtnState"
    },
    "ButtonBasic_pg_basiData_terminalDetails_edit": {
        "method": "GET",
        "path": "/terminalBtnEdit"
    },
    "ButtonBasic_pg_basiData_terminalMap_sign": {
        "method": "GET",
        "path": "/terminalBtnSign"
    },
    //单品按钮
    "ButtonBasic_pg_basiData_singleProductList_add": {
        "method": "GET",
        "path": "/singleProductBtnAdd"
    },
    "ButtonBasic_pg_basiData_singleProductList_state": {
        "method": "GET",
        "path": "/singleProductBtnState"
    },
    "ButtonBasic_pg_basiData_singleProductList_check": {
        "method": "GET",
        "path": "/singleProductBtnCheck"
    },
    //品牌按钮
    "ButtonBasic_pg_basiData_brand_add": {
        "method": "GET",
        "path": "/brandBtnAdd"
    },
    "ButtonBasic_pg_basiData_brand_edit": {
        "method": "GET",
        "path": "/brandBtnEdit"
    },
    "ButtonBasic_pg_basiData_brand_del": {
        "method": "GET",
        "path": "/brandBtnDel"
    },
    //品类
    "ButtonBasic_pg_basiData_category_add": {
        "method": "GET",
        "path": "/categoryBtnAdd"
    },
    "ButtonBasic_pg_basiData_category_edit": {
        "method": "GET",
        "path": "/categoryBtnEdit"
    },
    "ButtonBasic_pg_basiData_category_del": {
        "method": "GET",
        "path": "/categoryBtnDel"
    },
    //渠道
    "ButtonBasic_pg_basiData_channel_add": {
        "method": "GET",
        "path": "/channelBtnAdd"
    },
    "ButtonBasic_pg_basiData_channel_edit": {
        "method": "GET",
        "path": "/channelBtnEdit"
    },
    "ButtonBasic_pg_basiData_channel_del": {
        "method": "GET",
        "path": "/channelBtnDel"
    },
    //地区
    "ButtonBasic_pg_basiData_areaList_add": {
        "method": "GET",
        "path": "/areaBtnAdd"
    },
    "ButtonBasic_pg_basiData_areaList_state": {
        "method": "GET",
        "path": "/areaBtnState"
    },
    "ButtonBasic_pg_basiData_areaList_edit": {
        "method": "GET",
        "path": "/areaBtnEdit"
    },
    //节假日
    "ButtonBasic_pg_basiData_holidayList_create": {
        "method": "GET",
        "path": "/holidayBtnCreate"
    },
    "ButtonBasic_pg_basiData_holidayList_edit": {
        "method": "GET",
        "path": "/holidayBtnEdit"
    },
    // 基础数据
    "ButtonBasic_pg_basiData_unit_create": {
        "method": "GET",
        "path": "/unitBtnCreate"
    },
    "ButtonBasic_pg_basiData_unit_add": {
        "method": "GET",
        "path": "/unitBtnAdd"
    },
    "ButtonBasic_pg_basiData_unit_state": {
        "method": "GET",
        "path": "/unitBtnState"
    },
    "ButtonBasic_pg_basiData_unit_edit": {
        "method": "GET",
        "path": "/unitBtnEdit"
    },
}