const authcModule = {
    namespaced: true,//开启命名空间 
    state: () => ({
        authc: {},  //模块中的状态   页面调用(this as any).$store.state.authc.authc
    }),
    mutations: {
        //修改模块中的状态   页面调用(this as any).$store.commit('authc/setAuthc',3)
        setAuthc(state, authc) {
            state.authc = authc;
        }
    },
    actions: {},
    getters: {}
}
export default authcModule