import request from '../utils/request.js';
//地区列表
export function listArea(params) {
  return request().get(`/api/base/v1/area/findall`, {
    params: {
      ...params
    }
  })
}

export function saveArea(params) {
  return request().post('/api/base/v1/area/create', {
    ...params,
  })
}

//批量或者单个禁用地区
export function disableAreaByCode(params) {
  return request().post('/api/base/v1/area/batchDeleted', {
    ...params,
  })
}
//地区详情
export function getAreaByCode(code) {
  return request().get(`/api/base/v1/area/findCode?code=${code}`)
}

//地区编辑
export function updateAreaByCode(params) {
  return request().post('/api/base/v1/area/areaUpdate', {
    ...params,
  })
}


//节假日列表
export function listHoliday(params) {
  return request().get(`/api/base/v1/internalHoliday/findall`, {
    params: {
      ...params
    }
  })
}

//节假日详情
export function getHolidayByDate(date) {
  return request().get(`/api/base/v1/internalHoliday/findDate?date=${date}`)
}
//编辑
export function updateholidayByCode(params) {
  return request().post('/api/base/v1/internalHoliday/updateHoliday', {
    ...params,
  })
}
//节假日详情
export function generateHolidayData(year) {
  return request().get(`/api/base/v1/internalHoliday/init?year=${year}`)
}



// 主数据列表相关接口
//获取省份 城市  地区
export function getProvince() {
  return request().get('/api/base/v1/areaselect/province/find')
}
export function getCity(Code) {
  return request(Code).get(`/api/base/v1/areaselect/city/find?provinceCode=${Code}`)
}
export function getArea(Code) {
  return request(Code).get(`/api/base/v1/areaselect/area/find?cityCode=${Code}`)
}
export function provinceCityAread() {
  return request().get(`/api/base/v1/terminal/select/province/city/area`)
}


//获取终端列表信息
export function masterList(params) {
  return request().post(`/api/base/v1/terminal/find/list`, {
    ...params,
  })
}
//禁用终端
export function terminalDisable(params) {
  return request().post(`/api/base/v1/terminal/update/status`, {
    ...params,
  })
}
//获取镇
export function getTown(areaCode) {
  return request().get(`/api/base/v1/areaselect/town/find?areaCode=${areaCode}`)
}
//编辑终端
export function editTerminal(params) {
  return request().post(`/api/base/v1/terminal/update`, {
    ...params,
  })
}
//终端详情显示
export function getTerminalDetails(id) {
  return request().get(`/api/base/v1/terminal/find/detail?fmesCode=${id}`)
}
//获取子渠道下拉框
export function subchannelList(data) {
  return request().get(`/api/base/v1/channel/subchannel/list?subChannelName=${data}`)
}
//通路大类下拉框   
export function kindcategryList(kindCategoryName = '') {
  return request().get(`/api/base/v1/channel/kindcategry/list?kindCategoryName=${kindCategoryName}`)
}
//通路下拉框
export function kindList(data, kindName = '') {
  return request().get(`/api/base/v1/channel/kind/list?kindCategoryCode=${data}&kindName=${kindName}`)
}
//渠道下拉框
export function channeList(kindCategryCode, kindCode, channelName = '') {
  return request().get(`/api/base/v1/channel/channel/list?kindCategoryCode=${kindCategryCode}&kindCode=${kindCode}&channelName=${channelName}`)
}
//通路大类 通路 渠道联级
export function kindcategoryKindChannel() {
  return request().get(`/api/base/v1/terminal/select/kindcategory/kind/channel`)
}
//营业执照识别  ----待修改 暂定不修改了
export function licenseIdentification(url) {
  return request().get(`/api/terminal/v1/terminal/getLicense?imageUrl=${url}`)
}
//上传图片  
export function imageUpload(formData) {
  return request().post(`/api/base/v1/file/fileUpload`, formData)
}




//////////////////////////////////////////////品类相关
//获取品类列表
export function categoryList(code) {
  return request().get(`/api/base/v1/categoryList?categoryCode=${code}`)
}
//新增品类
export function addCategory(params) {
  return request().post(`/api/base/v1/addCategory`, {
    ...params
  })
}
//编辑品牌及排序
export function updateCategory(params) {
  return request().post(`/api/base/v1/updateCategory`, {
    ...params
  })
}
//删除品类
export function deleteCategory(code) {
  return request().post(`/api/base/v1/deleteCategory?categoryCode=${code}`)
}
//更改后的品类全部排序
export function sortCategory(params) {
  return request().post(`/api/base/v1/sortCategory`, {
    ...params
  })
}
// 搜索查询品类 
export function searchCategory(name, percode) {
  return request().get(`/api/base/v1/searchCategory?categoryName=${name}&categoryPreCode=${percode}`)
}

///////////////////////////////////////////////////////////品牌相关
//获取品牌列表
export function brandList(pageNumber, pageSize) {
  return request().get(`/api/base/v1/brandList?pageNumber=${pageNumber}&pageSize=${pageSize}`)
}
//删除品牌
export function deleteBrand(code) {
  return request().post(`/api/base/v1/deleteBrand?code=${code}`)
}
//新增品牌
export function addBrand(params) {
  return request().post(`/api/base/v1/addBrand`, {
    ...params
  })
}
//查询品牌联级
export function brandDownList(code) {
  return request().get(`/api/base/v1/brandDownList?code=${code}`)
}
//编辑品牌
export function updateBrand(params) {
  return request().post(`/api/base/v1/updateBrand`, {
    ...params
  })
}
//获取全部品牌
export function allBrandList() {
  return request().get(`/api/base/v1/brandList`)
}
// 品牌->文件上传
export function upload(formData) {
  return request().post(`/api/singleproduct/v1/upload`, formData)
}
//通过名字查找品牌 
export function brandListByName(pageNumber, pageSize, barndName) {
  return request().get(`/api/base/v1/brandDownListByName?pageNumber=${pageNumber}&pageSize=${pageSize}&brandName=${barndName}`)
}



///////////////////////////////////////////////////////////渠道管理相关
//直接使用了终端中的接口即可
//新增渠道
export function channelAdd(params) {
  return request().post(`/api/base/v1/channel/add`, {
    ...params
  })
}
//编辑渠道
export function channelUpdate(params) {
  return request().post(`/api/base/v1/channel/update`, {
    ...params
  })
}
//删除渠道
export function channelDelete(params) {
  return request().post(`/api/base/v1/channel/delete`, {
    ...params
  })
}
//新增子渠道
export function subchannelAdd(params) {
  return request().post(`/api/base/v1/channel/add`, {
    ...params
  })
}
//编辑子渠道
export function subchannelUpdate(params) {
  return request().post(`/api/base/v1/channel/update`, {
    ...params
  })
}
//删除子渠道
export function subchannelDelete(params) {
  return request().post(`/api/base/v1/channel/delete`, {
    ...params
  })
}
//查询子渠道
export function subchannelLists(kindCategoryCode, kindCode, channelCode, subChannelName = '') {
  return request().get(`/api/base/v1/channel/subchannel/lists?kindCategoryCode=${kindCategoryCode}&kindCode=${kindCode}&channelCode=${channelCode}&subChannelName=${subChannelName}`)
}


//----------------------------------------------------------------------------> 单品相关
//单品列表
export function listSingleProduct(params) {
  return request().post(`/api/base/v1/product/list`, {
    ...params
  })
}
//批量或者单个禁用单品
export function disableSingleProductById(params) {
  return request().post(`/api/base/v1/product/disable`, {
    ...params,
  })
}
//单品反显
export function getSingleProductById(id) {
  return request().get(`/api/base/v1/product/get?spBarCode=${id}`)
}
//根据子品牌查品牌关系
export function externalBrandRelation(code) {
  return request().get(`/api/singleproduct/v1/externalBrandRelation?code=${code}`)
}
//根据子品类查品类关系
export function externalCategoryRelation(code) {
  return request().get(`/api/singleproduct/v1/externalCategoryRelation?code=${code}`)
}
//单品编辑
export function updateSingleProductById(params) {
  return request().post(`/api/singleproduct/v1/product/update`, {
    ...params,
  })
}
//新增单品
export function saveSingleProduct(params) {
  return request().post(`/api/base/v1/product/save`, {
    ...params,
  })
}
//单品详情页面的单位列表
export function listBaseValueByCode() {
  return request().get(`/api/base/v1/basedataApi/listBaseValueByCode?code=PRODUCT_UNIT`)
}
//单品校验列表
export function productCheck(params) {
  return request().post(`/api/base/v1/product/check`, {
    ...params,
  })
}
//更新校验单品 
export function updateLog(params) {
  return request().post(`/api/base/v1/product/updateLog`, {
    ...params,
  })
}
//更新校验单品 
export function productUpdate(params) {
  return request().post(`/api/base/v1/product/update`, {
    ...params,
  })
}

//单品 二期相关待修改
//获取单品模板
// export function getSingleProductImportTemplateExcel(params) {
//   return request().post(`/api/singleproduct/v1/product/getTemplate`, {
//     ...params,
//   })
// }
// //单品批量导入
// export function singleProductImport(formData) {
//   return request().post(`/api/singleproduct/v1/product/import`, formData)
// }
// //获取单品导入状态
// export function singleproductGetImportStatus(key) {
//   return request().get(`/api/singleproduct/v1/product/getStatus?key=SINGLEPRODUCT`)
// }
// //重置导入状态
// export function singleproductReplaceImportStatus(key) {
//   return request().get(`/api/singleproduct/v1/product/reload?key=SINGLEPRODUCT`)
// }
// //单品错误提示
// export function singleproductGetErrorMsgUrl(key) {
//   return request().get(`/api/singleproduct/v1/product/getError?key=SINGLEPRODUCT`)
// }
// //批量导出单品
// export function exportSingleProductList(params) {
//   return request().post(`/api/singleproduct/v1/product/export`, {
//     ...params,
//   })
// }



//基础数据相关
//获取列表
export function unitList(params) {
  return request().post(`/api/base/v1/basedata/list`, {
    ...params,
  })
}
//新增数据
export function unitAdd(params) {
  return request().post(`/api/base/v1/basedata/add`, {
    ...params,
  })
}
//编辑数据
export function unitUpdate(params) {
  return request().post(`/api/base/v1/basedata/update`, {
    ...params,
  })
}
