import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import MasterData from "../views/masterData/MasterData.vue";
import store from "../store";
import { afterLogout } from "@/utils/requestFunction.js";

const routes: any = [
  {
    path: "/",
    name: "/",
    redirect: "/masterData",
  },
  {
    path: "/masterData",
    name: "平台主数据管理中心",
    component: MasterData,
    meta: { hidden: false, title: "平台主数据管理中心" },
    children: [
      {
        path: "/terminal",
        name: "终端管理",
        redirect: "/terminalList",
        component: () => import("../views/masterData/terminal/Terminal.vue"),
        meta: { hidden: false, title: "终端管理", key: 'MenuBasic_pg_basiData_terminal' },
        children: [
          {
            name: "终端列表",
            path: "/terminalList",
            component: () =>
              import("../views/masterData/terminal/TerminalList.vue"),
            meta: { hidden: false, title: "终端列表", key: 'MenuBasic_pg_basiData_terminalList' },
          },
          {
            name: "终端地图",
            path: "/terminalMap",
            component: () =>
              import("../views/masterData/terminal/TerminalMap.vue"),
            meta: { hidden: false, title: "终端地图", key: 'MenuBasic_pg_basiData_terminalMap' },
          },
          {
            name: "终端信息",
            path: "/terminalDetails/:id",
            component: () =>
              import("../views/masterData/terminal/TerminalDetails.vue"),
            meta: {
              hidden: true,
              title: "终端信息",
              breadcrumb: [
                { title: "平台主数据管理中心" },
                { title: "终端管理" },
                { title: "终端列表", path: "/terminalList" },
                { title: "终端信息" },
              ],
              key: 'MenuBasic_pg_basiData_terminalDetails'
            },
          },
        ],
      },
      {
        path: "/singleProduct",
        name: "单品管理",
        redirect: "/singleProductList",
        component: () =>
          import("../views/masterData/singleProduct/SingleProduct.vue"),
        meta: { hidden: false, title: "单品管理", key: 'MenuBasic_pg_basiData_singleProduct' },
        children: [
          {
            name: "单品列表",
            path: "/singleProductList",
            component: () =>
              import("../views/masterData/singleProduct/SingleProductList.vue"),
            meta: { hidden: false, title: "单品列表", key: 'MenuBasic_pg_basiData_singleProductList' },
          },
          {
            name: "单品信息",
            path: "/singleProductDetails/:id",
            component: () =>
              import(
                "@/views/masterData/singleProduct/SingleProductDetails.vue"
              ),
            meta: {
              hidden: true,
              title: "单品信息",
              breadcrumb: [
                { title: "平台主数据管理中心" },
                { title: "单品管理" },
                { title: "单品列表", path: "/singleProductList" },
                { title: "单品信息" },
              ],
              key: 'MenuBasic_pg_basiData_singleProductDetails'
            },
          },
        ],
      },
      {
        path: "/other",
        redirect: "/brand",
        name: "其他",
        component: () => import("../views/masterData/other/Other.vue"),
        meta: { hidden: false, title: "其他", key: 'MenuBasic_pg_basiData_other' },
        children: [
          {
            name: "品类管理",
            path: "/category",
            component: () => import("../views/masterData/other/Category.vue"),
            meta: { hidden: false, title: "品类管理", key: 'MenuBasic_pg_basiData_category' },
          },
          {
            name: "品牌管理",
            path: "/brand",
            component: () => import("@/views/masterData/other/Brand.vue"),
            meta: { hidden: false, title: "品牌管理", key: 'MenuBasic_pg_basiData_brand' },
          },
          {
            name: "渠道管理",
            path: "/channel",
            component: () => import("../views/masterData/other/Channel.vue"),
            meta: { hidden: false, title: "渠道管理", key: 'MenuBasic_pg_basiData_channel' },
          },
          {
            path: "/masterData/area/list",
            name: "地区列表",
            component: () => import("../views/masterData/area/AreaList.vue"),
            meta: { hidden: false, title: "地区列表", key: 'MenuBasic_pg_basiData_area_list' },
          },
          {
            name: "地区信息",
            path: "/masterData/area/details/:code",
            component: () => import("../views/masterData/area/AreaDetails.vue"),
            meta: {
              hidden: true,
              title: "地区信息",
              breadcrumb: [
                { title: "平台主数据管理中心" },
                { title: "其他" },
                { title: "地区信息", path: "/masterData/area/list" },
                { title: "地区信息" },
              ],
              key: 'MenuBasic_pg_basiData_area_details'
            },
          },

          {
            path: "/masterData/holiday/list",
            name: "节假日列表",
            component: () =>
              import("../views/masterData/holiday/HolidayList.vue"),
            meta: { hidden: false, title: "节假日列表", key: 'MenuBasic_pg_basiData_holiday_list' },
          },
          {
            name: "节假日信息",
            path: "/masterData/holiday/details/:date",
            component: () =>
              import("../views/masterData/holiday/HolidayDetails.vue"),
            meta: {
              hidden: true,
              title: "节假日信息",
              breadcrumb: [
                { title: "平台主数据管理中心" },
                { title: "其他" },
                { title: "节假日列表", path: "/masterData/holiday/list" },
                { title: "节假日信息" },
              ],
              key: 'MenuBasic_pg_basiData_holiday_details'
            },
          },
          {
            name: "基础属性管理",
            path: "/unit",
            component: () => import("@/views/masterData/other/Unit.vue"),
            meta: { hidden: false, title: "基础属性管理", key: 'MenuBasic_pg_basiData_unit' },
          },
        ],
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  if (store.state.isLogin === false) {
    afterLogout().then((res) => {
      if (res.data.success) {
        store.commit("changeisLogin", true);
        next();
      }
    });
  } else {
    next();
  }
});

export default router;
